import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature from "components/features/TwoColWithButton.js";
// import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
// import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/FiveColumnDark.js";
// import paintingImageSrc from "images/custom/painting-image.svg"
import paintingImageSrc from "images/custom/paint.jpg"
// import buildingImageSrc from "images/custom/building-image.svg"
import buildingImageSrc from "images/custom/transform.jpg"

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <Features />
      <MainFeature 
        subheading="Vibrant Colors"
        heading="Freshen Up Your Space"
        description="Bring your space to life with our professional painting services. From bold colors to subtle hues, we ensure every wall reflects your style and vibe. Our expert painters use high-quality paints for a lasting finish, transforming your home or office into a vibrant space you'll love."
        imageSrc={paintingImageSrc}
        show={false}
      />
      <MainFeature 
        subheading="Full Makeover"
        heading="Transform Your Space"
        description="Dive into a full-scale renovation with our skilled team. Whether it's updating plumbing, laying down new floors, or constructing dream spaces, we handle it all. Our team works diligently to rebuild and enhance every corner, ensuring the final outcome not only meets but exceeds your expectations."
        imageSrc={buildingImageSrc}
        show={false}
        textOnLeft={false}
      />
      {/* <Testimonial /> */}
      <FAQ />
      <Footer />
    </AnimationRevealPage>
  );
}
