import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import styled from "styled-components"; //eslint-disable-line
// import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark.js";
// import SingleCol from "components/faqs/SingleColResidential";
import Blog from "components/blogs/GridWithFeaturedPostResidential.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Blog />
      {/* <SingleCol /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
