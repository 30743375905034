import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { AiOutlineClockCircle, AiOutlineStar, AiOutlineFormatPainter, AiOutlineWallet, AiOutlineSmile, AiOutlineHeart } from "react-icons/ai";

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`;

export default ({
  cards = null,
  heading = "Amazing Features",
  subheading = "",
  description = "Get the best with HB General Contractors. Our services are designed to meet all your renovation needs with excellence and care."
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: <AiOutlineClockCircle color="#a08643" size={30} alt="" />,
      title: "Quick Turnaround",
      description: "No more waiting forever for your renovations. We work fast to get your space looking great as soon as possible."
    },
    {
      imageSrc: <AiOutlineStar color="#a08643" size={30} alt="" />,
      title: "Quality Work",
      description: "Only the best for you. We use top materials and skilled workers to make sure your place shines."
    },
    {
      imageSrc: <AiOutlineFormatPainter color="#a08643" size={30} alt="" />,
      title: "Custom Designs",
      description: "Your space, your way. We listen to what you want and make it happen, from colors to layouts."
    },
    {
      imageSrc: <AiOutlineWallet color="#a08643" size={30} alt="" />,
      title: "Reliable",
      description: "Great work that won’t break the bank. We offer fantastic service at prices that make sense for you."
    },
    {
      imageSrc: <AiOutlineSmile color="#a08643" size={30} alt="" />,
      title: "Friendly Team",
      description: "We’re here for you. Our team is friendly and ready to help with any questions or ideas you have."
    },
    {
      imageSrc: <AiOutlineHeart color="#a08643" size={30} alt="" />,
      title: "Satisfaction Guaranteed",
      description: "Happy home, happy life. We don’t stop until you love the changes we’ve made."
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container style={{borderTop: "1px solid #FFF"}}>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                {card.imageSrc || <img src={defaultCardImage} alt="" />}
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || ""}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
