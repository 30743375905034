import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy & Terms of Use" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>
              Welcome to HB General Contractors ("Company"). Before using our sites, please carefully read
              this Privacy Policy, which sets out how we gather and use information on our sites.
              The Company is dedicated to providing safe websites for visitors of all ages and we
              are committed to protecting your privacy. We comply with Canadian Federal and Provincial
              privacy laws and regulations, including the Personal Information and Electronic Documents Act.
              <br />
              <br />
              Please note that this Privacy Policy applies only to our Sites and not to websites of other
              companies or organizations that may be linked to our Sites. You should review the privacy policy
              of any linked site and/or contact the operator of the linked site for more information. These
              links are provided for informational purposes only and as a convenience to our visitors. The
              Company does not endorse or accept any responsibility for the content or privacy
              practices of linked websites.
              <br />
              <br />
              While registration is not required to access our Sites, you may be asked to provide personal
              information when visiting certain sections of our Sites. By using our Sites, you acknowledge
              and consent to our Privacy Policy. If you do not agree with this Privacy Policy, please do not
              continue to use our Sites. Your continued use of the Sites signifies your acceptance of these
              terms and any changes in effect at the time of use.
              <br />
              <br />
              Personal Information refers to information that identifies you as an individual, such as your
              name, address, email address, or telephone number.
              <br />
              <br />
              We collect information that you voluntarily provide to us through surveys, search functions,
              questionnaires, feedback forms, and similar tools. Some of our Sites may offer health assessment
              tools that require you to provide self-assessment information. We may also ask for additional
              information, such as your email address, if you want to receive additional services, information,
              participate in a contest, or to address complaints or concerns.
              <br />
              <br />
              Please be advised that any personal information gathered on our Sites may be used in aggregate
              for research and development related to our Sites and future site development. If you provide us
              with personal information and request it, we may also use it to send you promotional materials.
              Specifically, we may use information gathered about you for the following purposes: to monitor
              interest in our products and tailor the content of our Sites to your preferences by tracking page
              views and collecting information about your preferences; to create a profile about you and display
              content that might interest you based on your preferences; and, if you indicate that you wish to
              receive additional information, to send you information about us, promotional material about our
              products, and details of any available offers from time to time.
              <br />
              <br />
              With your permission, we may use information submitted at the time of registration or submission
              for marketing and promotional purposes, as long as we provide notice of this fact online. If you
              object to this use, you may prevent it by either sending an email request or modifying your registration
              information. The Company takes reasonable measures to protect your personal information. If you have
              submitted personal information and wish to change or opt-out, please contact us using the information provided.
              <br />
              <br />
              The Company will not disclose personal information you provide on its Sites to any third party except:
              i) in accordance with the terms of this Privacy Policy; ii) to comply with legal requirements, such as
              a law, regulation, warrant, subpoena, or court order; and/or iii) if you are reporting an adverse event
              or side effect, in which case the Company may be required to disclose such information to Canadian and/or
              international regulatory authorities. Please note that any of these disclosures may involve the storage or
              processing of personal information outside of Canada and may therefore be subject to different privacy
              laws than those applicable in Canada, including laws that require the disclosure of personal information
              to governmental authorities under circumstances that are different from those in Canada.
              <br />
              <br />
              Like many website operators, the Company may use "cookies" on its Sites. Cookies are small data files
              that are downloaded onto your computer when you visit a website. You can disable cookies by turning them
              off in your browser; however, please note that some areas of the Sites may not function properly if you do so.
              <br />
              <br />
              For our Sites that require registration, additional terms apply. Generally, you are not required to provide
              personal information to use our Sites, except as necessary to provide a product or service you have requested.
              However, some of our Sites are restricted to certain individuals, such as healthcare professionals or
              prescription drug patients, and we may require them to register by providing certain information upon entry.
              <br />
              <br />
              We are committed to the security of your information and have implemented physical, electronic, and
              managerial procedures to help prevent unauthorized access, maintain data security, and use the
              information we collect online appropriately. The Company applies security measures that are appropriate
              to the sensitivity of the information, such as storing information in secure facilities and limiting
              access to personal information to authorized employees on a need-to-know basis.
              <br />
              <br />
              Personal information that you share with us is stored on our database servers at Company data centers,
              regardless of their location, or hosted by third parties who have entered into agreements with us
              requiring them to follow our Privacy Policy.
              <br />
              <br />
              Disclaimer: The information provided on this website is for informational purposes only. We make no
              guarantees or warranties about the accuracy, completeness, or reliability of the information. Any
              action you take based upon the information on this website is strictly at your own risk. We will
              not be liable for any losses and damages in connection with the use of our website. We recommend
              that you always verify the information on this website with other sources before relying on it.
              <br />
              <br />
              If we make changes to our Privacy Policy, we will post them on this page of our Site so that you are
              always aware of the information we collect about you, how we use it, and the circumstances
              under which we may disclose it.
              <br />
              <br />
              By using this Site or any other The Company Site or interactive banner ads, you acknowledge and agree
              to our Privacy Policy and the terms and conditions posted on the Site. By submitting your information,
              you agree that it will be governed by our Privacy Policy.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
