import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
// import styled from "styled-components"; //eslint-disable-line
// import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

// import TorontoImageSrc from "images/custom/toronto.jpg"
import TorontoImageSrc from "images/custom/working.jpg"
import QualityImageSrc from "images/custom/quality.jpg"

import { AiOutlineFieldTime, AiOutlineCrown, AiOutlineEdit } from "react-icons/ai";
import SingleCol from "components/faqs/SingleCol";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      {/* FIXTHIS */}
      <MainFeature1
        subheading={<Subheading>ABOUT HB GENERAL CONTRACTORS</Subheading>}
        heading="We are Toronto's go-to experts for transforming spaces."
        buttonRounded={false}
        // primaryButtonText="See Our work"
        // primaryButtonUrl="#"
        imageSrc={TorontoImageSrc}
      />
      <SingleCol />
      <MainFeature1
        subheading={<Subheading>OUR VISION</Subheading>}
        heading="We aim to redefine renovation excellence."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl="contact-us"
        imageSrc={QualityImageSrc}
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>OUR VALUES</Subheading>}
        heading="We follow these standards."
        description="We stand by our commitment to quality, timeliness, and customer satisfaction."
        cards={[
          {
            imageSrc: <AiOutlineFieldTime color="#a08643" size={30} alt="" />,
            title: "Fast Service",
            description: "Our team ensures your renovations are completed quickly, without sacrificing quality. Experience swift transformations of your space to fit your timeline.",
            url: '/contact-us'
          },
          {
            imageSrc: <AiOutlineCrown color="#a08643" size={30} alt="" />,
            title: "Top Quality",
            description: "We're committed to delivering exceptional workmanship. Using only the best materials and skilled professionals, we guarantee your space will look and feel superior.",
            url: '/contact-us'
          },
          {
            imageSrc: <AiOutlineEdit color="#a08643" size={30} alt="" />,
            title: "Personal Touch",
            description: "Your vision is our blueprint. From initial concept to final touches, we customize every aspect of your renovation to meet your unique preferences and needs.",
            url: '/contact-us'
          },
        ]}
        linkText=""
      />
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
