import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";

const PrimaryBackgroundContainer = tw(Container)`-mx-8 px-8 bg-primary-900 text-gray-100`;

const HeadingContainer = tw.div``;
const Subheading = tw(SubheadingBase)`text-center text-gray-100 mb-4`;
const Heading = tw(SectionHeading)``;
const Description = tw(SectionDescription)`mx-auto text-center text-gray-300`;

const FaqsContainer = tw.div`mt-10 sm:mt-16 w-full flex-1 lg:flex justify-between items-start max-w-screen-lg mx-auto`;
const FaqsColumn = tw.div`w-full lg:max-w-lg lg:mr-12 last:mr-0`;
const Faq = tw.div`select-none cursor-pointer border-b-2 border-primary-300 hover:border-primary-500 transition-colors duration-300 py-6`;
const Question = tw.div`flex justify-between items-center`;
const QuestionText = tw.div`text-sm sm:text-lg font-semibold tracking-wide`;
const QuestionToggleIcon = styled(motion.span)`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw(motion.div)`hidden text-sm font-normal mt-4 text-gray-300`;

export default ({
  subheading = "",
  heading = "Frequently Asked Questions",
  description = "",
  faqs = [
    {
      question: "What services does HBGeneralcontractors offer in renovation and construction?",
      answer:
        "Our dedication to excellence sets HBGeneralcontractors apart from the competition. We pride ourselves on our meticulous attention to detail and a personalized project approach, ensuring every client receives unparalleled service. Our team delivers exceptional craftsmanship and innovative design solutions, all while providing outstanding customer service to exceed our clients' expectations."
    },
    {
      question: "How does HBGeneralcontractors stand out in the renovation and construction industry?",
      answer:
        "Our dedication to excellence sets HBGeneralcontractors apart from the competition. We pride ourselves on our meticulous attention to detail and a personalized project approach, ensuring every client receives unparalleled service. Our team delivers exceptional craftsmanship and innovative design solutions, all while providing outstanding customer service to exceed our clients' expectations."
    },
    {
      question: "What measures does HBGeneralcontractors take to ensure quality and safety during construction?",
      answer:
        "Quality and safety are paramount at HBGeneralcontractors. We implement rigorous quality control protocols and adhere to industry-leading practices at every project stage. Our professionals undergo continuous training and certification to maintain the highest safety standards, ensuring a safe and efficient construction environment."
    },
    {
      question: "Can HBGeneralcontractors manage projects with varying complexities and sizes?",
      answer:
        "Absolutely. HBGeneralcontractors possesses the expertise and resources necessary to undertake projects of any scale or complexity. Whether it's a minor renovation or a major commercial build, our team works closely with clients to deliver custom solutions that not only meet but exceed their project specifications and expectations."
    },
    {
      question: "How does HBGeneralcontractors handle project management and client communication?",
      answer:
        "We believe in a transparent and cooperative approach to project management and communication. A dedicated project manager is assigned to each project to ensure smooth operations, from regular updates and prompt issue resolution to maintaining open lines of communication with our clients throughout the project's duration."
    },
    {
      question: "What approach does HBGeneralcontractors take towards budgeting and cost management in projects?",
      answer:
        "HBGeneralcontractors adopts a detailed and transparent method for budgeting and cost management, aiming to complete projects within the agreed-upon budget without sacrificing quality. We offer comprehensive cost estimates and upfront pricing, monitor expenses closely during the project, and seek out opportunities to optimize the budget, ensuring the most efficient use of resources.",
    }
  ]
}) => {
  const faqCol1 = [];
  const faqCol2 = [];
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  faqs.map((faq, index) => {
    const renderedFaq = (
      <Faq key={index} onClick={() => toggleQuestion(index)}>
        <Question>
          <QuestionText>{faq.question}</QuestionText>
          <QuestionToggleIcon
            variants={{
              collapsed: { rotate: 0 },
              open: { rotate: -180 }
            }}
            initial="collapsed"
            animate={activeQuestionIndex === index ? "open" : "collapsed"}
            transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <ChevronDownIcon />
          </QuestionToggleIcon>
        </Question>
        <Answer
          variants={{
            open: { opacity: 1, height: "auto", marginTop: "16px", display: "block" },
            collapsed: { opacity: 0, height: 0, marginTop: "0px", display: "none" }
          }}
          initial="collapsed"
          animate={activeQuestionIndex === index ? "open" : "collapsed"}
          transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          {faq.answer}
        </Answer>
      </Faq>
    );

    if (index % 2 === 0) faqCol1.push(renderedFaq);
    else faqCol2.push(renderedFaq);

    return null;
  });
  return (
    <PrimaryBackgroundContainer>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          <Description>{description}</Description>
        </HeadingContainer>
        <FaqsContainer>
          <FaqsColumn>{faqCol1}</FaqsColumn>
          <FaqsColumn>{faqCol2}</FaqsColumn>
        </FaqsContainer>
      </ContentWithPaddingXl>
    </PrimaryBackgroundContainer>
  );
};
