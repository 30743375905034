import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

import * as yup from "yup";
import { useFormik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`pl-2 mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contact Us",
  heading = <>Let's Talk About Your Next Project</>,
  description = "Ready to start your renovation journey or have questions about our services? We're here to help. Reach out to HB General Contractors by filling out the form below. Share your ideas, questions, or needs, and our team will get back to you as soon as possible to make your renovation dreams come to life.",
  submitButtonText = "Send",
  formAction = "https://formsubmit.co/info@hbgeneralcontractors.ca",
  formMethod = "POST",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [recaptchaVerified, setRecaptchaVerified] = useState(false);

  const navigate = useNavigate();

  const schema = yup.object({
    name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Name must be alphabetic and may contain spaces")
      .max(50, "Maximum 50 characters allowed")
      .required("First name is required"),
    email: yup
      .string()
      .email("Email is not valid")
      .max(50, "Maximum 50 characters allowed")
      .required("Email is required"),
    phone: yup
      .string()
      .matches(/^\d{0,11}$/, "Phone number must be a number and less than 12 digits")
      .optional(),
    message: yup
      .string()
      .required("Message is required")
      .max(500, "Maximum 500 characters allowed"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      if (recaptchaVerified) {
        setIsSubmitting(true);

        try {
          // Submit form data to formsubmit
          const response = await fetch(`https://formsubmit.co/ajax/info@hbgeneralcontractors.ca`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          });

          if (!response.ok) {
            throw new Error("Failed to submit form");
          }

          toast.success("Form Submitted Successfully! We'll get back too you ASAP");

          setTimeout(() => {
            navigate('/thank-you')
          }, 4000);
        } catch (error) {
          toast.error("Error! ", error.message);
        } finally {
          setIsSubmitting(false);
        }
      } else {
        toast.error("Error! Please verify the reCAPTCHA");
      }
    },
  });

  const handleInputChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value);
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form action={formAction} method={formMethod} onSubmit={formik.handleSubmit}>
              <Input
                type="text"
                name="name"
                placeholder="Full Name*"
                onChange={handleInputChange}
                // Update the styling based on error state
                css={formik.touched.name && formik.errors.name && tw`border-red-500`}
              />
              {formik.touched.name && formik.errors.name && (
                <div css={tw`text-red-500 text-xs mt-1`}>{formik.errors.name}</div>
              )}
              <Input
                type="email"
                name="email"
                placeholder="Email Address*"
                onChange={handleInputChange}
                css={formik.touched.email && formik.errors.email && tw`border-red-500`}
              />
              {formik.touched.email && formik.errors.email && (
                <div css={tw`text-red-500 text-xs mt-1`}>{formik.errors.email}</div>
              )}
              <Input
                type="tel"
                name="phone"
                placeholder="Phone Number"
                onChange={handleInputChange}
                // Update the styling based on error state
                css={formik.touched.phone && formik.errors.phone && tw`border-red-500`}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div css={tw`text-red-500 text-xs mt-1`}>{formik.errors.phone}</div>
              )}
              <Textarea
                name="message"
                placeholder="Message*"
                onChange={handleInputChange}
                // Update the styling based on error state
                css={formik.touched.message && formik.errors.message && tw`border-red-500`}
              />
              {formik.touched.message && formik.errors.message && (
                <div css={tw`text-red-500 text-xs mt-1`}>{formik.errors.message}</div>
              )}
              <ReCAPTCHA className="g-recaptcha" css={tw`mt-3`} sitekey="6Le2VG8pAAAAANF_0hb6C55Mf9ak-IUMeJNssiT7" onChange={() => setRecaptchaVerified(true)} />
              <SubmitButton type="submit">
                {isSubmitting ? <span>Loading...</span> : submitButtonText }
              </SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
